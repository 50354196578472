import Vue from 'vue'

import Notification  from './VueBulmaNotification';
//import BugsnagClient from '@utilities/bugsnag';

const NotificationComponent = Vue.extend(Notification);
const openNotification      = (propsData = {
        title: '',
        message: '',
        type: '',
        direction: '',
        duration: '',
        container: '.notifications'
    }) => {
        return new NotificationComponent({
            el: document.createElement('div'),
            propsData
        });
    };


export const notifySuccess = function (message) {
    openNotification({
        message: message,
        type:    'success'
    });
};


export const notifyError = function (message) {
    openNotification({
        message: message,
        type:    'danger'
    });

    //BugsnagClient.notify(message);
};

