<template>
    <header>
        <a :href="isGrantedBookKeeper ? '/invoices' : '/user-dashboard'" class="logo light-version">
            <img :src="'/clientSpecific/logo/logo_transparent.png'" alt="Logo" />
        </a>


        <div class="header-link hide-menu" @click="toggleMenu"><i class="material-icons">more_vert</i></div>

        <button
            class="btn-show-left-side-on-small"
            :class="{'is-visible': infos.showButton}"
            @click.prevent="showInfos"
        >
            <i class="material-icons">keyboard_backspace</i> Infos
        </button>

        <div class="page-name" v-if="pageName">{{ pageName }}</div>

        <nav role="navigation" class="inside-page-navigation">
            <template v-if="orderID">
                <template v-for="item in changeOrderNavigation">
                    <a v-bind="orderLink(`${item.url}`)"  :class="{'is-hidden-on-small': !item.showOnSmall}">{{item.label}}</a>
                </template>
                <button class="btn-menu-on-small" @click.prevent="toggleOrderMenu">Mehr <i class="material-icons">keyboard_arrow_down</i></button>
                <div class="menu-on-small" :class="{'is-active': orderMenu.isActive, 'is-visible': orderMenu.isVisible}">
                    <template v-for="item in changeOrderNavigation">
                        <a v-bind="orderLink(`${item.url}`)">{{item.label}}</a>
                    </template>
                </div>
            </template>
        </nav>

        <input v-if="!isGrantedBookKeeper" type="text" placeholder="Schnellsuche" class="nav-search" role="search" v>

        <button
            class="btn-show-right-side-on-small"
            :class="{'is-visible': orderID}"
            @click.prevent="showDashboard"
        >
            <i class="material-icons">keyboard_backspace</i> Infos
        </button>

        <div class="simple-menu btn-help is-pointer" v-if="!isGrantedBookKeeper" v-on:click.prevent="toggleFaq">
            <i class="material-icons">live_help</i>
        </div>

        <div class="simple-menu btn-help is-pointer" v-if="!isGrantedBookKeeper" v-on:click.prevent="toggleUserSettings">
            <i class="material-icons">settings</i>
        </div>
<!--        -->
<!--        <button class="simple-menu btn-camera is-pointer" id="capture"  v-on:click="capture">
            <i class="material-icons" v-bind:class="{ 'button is-loading' : loading }">{{ 'photo_camera'}}</i>
        </button>-->


    </header>
</template>

<script>
import SimpleMenu from '@components/SimpleMenu'
import {settings} from "@clientSpecific/utilities/settings";
import {currentUser} from "@utilities/functions";
export default {
    data() {
        return {
            orderMenu: {
                isActive: false,
                isVisible: false
            },
            loading: false,
        }
    },

    computed: {
        isGrantedBookKeeper: function() {
            let loggedInUser = currentUser();
            return loggedInUser && loggedInUser.roles && loggedInUser.roles.includes('ROLE_BOOK_KEEPER');
        },
        changeOrderNavigation() {
            return settings.order.navigation;
        },
        pageName() {

            return this.$layout.pageName
        },

        infos() {
            return this.$layout.infos
        },

        orderID() {
            const pathName = window.location.pathname;
            if (pathName.startsWith('/orders/') && pathName !== '/orders/add') {
                return parseInt(pathName.split('/')[2], 10);
            }
            return false;
        },
    },


    methods: {
        disableLoading: function() {
          this.loading = false;
        },
        capture: function () {
            this.loading = true;
            setTimeout(() => {
                this.$emit('capture')
            }, "200");
        },
        showInfos() {
            this.$layout.showInfos()
        },

        showDashboard() {
            this.$layout.showDashboard()
        },

        orderLink(subpage = '') {
            const href = `/orders/${this.orderID}${subpage !== '' ? `/${subpage}` : ''}`
            return {
                href,
                class: {
                    'is-active': href === window.location.pathname
                }
            }
        },

        toggleOrderMenu() {
            if (this.orderMenu.isActive) {
                this.orderMenu.isVisible = false;
                setTimeout(() => { this.orderMenu.isActive = false }, 200);
            } else {
                this.orderMenu.isActive = true;
                setTimeout(() => { this.orderMenu.isVisible = true }, 200);
            }
        },

        toggleMenu() {
            this.$layout.toggleMenu()
        },

        toggleFaq() {
            this.$layout.toggleFaq()
        },

        toggleUserSettings() {
            this.$layout.toggleUserSettings()
        }
    },

    components: {
        SimpleMenu
    }
}
</script>
