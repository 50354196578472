<template>
    <multiselect
        v-model                 = "organisationSelected"
        v-bind:options          = "organisations"
        :multiple               = "multiple"
        :internal-search        = "false"
        track-by                = "id"
        label                   = "name"
        placeholder             = "Organisationen wählen"
        v-bind:loading          = "isLoading"
    />
</template>



<script>

import Multiselect from './Multiselect';
import {getOrganisationsFromStorage} from "@utilities/functions";




export default {


    props: {
        value:       {},
        exportOptions: {
            default: false
        },
        multiple: {
            default: true,
        }
    },

    methods: {
        getOrganisationsFromStorage
    },

    computed: {
        organisationSelected: {
            get: function () {
                return this.value;
            },
            set: function (organisations) {
                this.$emit('input', organisations)
            }
        },
    },
    created: function () {
        this.organisations = this.getOrganisationsFromStorage();
        this.isLoading = false;
    },


    data: function () {
        return {
            organisations: [],
            isLoading: true
        }
    },

    components: {
        Multiselect
    }
}
</script>
