var render = function render() {
  var _vm = this,
    _c = _vm._self._c
  return _c("header", [
    _c(
      "a",
      {
        staticClass: "logo light-version",
        attrs: {
          href: _vm.isGrantedBookKeeper ? "/invoices" : "/user-dashboard",
        },
      },
      [
        _c("img", {
          attrs: {
            src: "/clientSpecific/logo/logo_transparent.png",
            alt: "Logo",
          },
        }),
      ]
    ),
    _vm._v(" "),
    _c(
      "div",
      { staticClass: "header-link hide-menu", on: { click: _vm.toggleMenu } },
      [_c("i", { staticClass: "material-icons" }, [_vm._v("more_vert")])]
    ),
    _vm._v(" "),
    _c(
      "button",
      {
        staticClass: "btn-show-left-side-on-small",
        class: { "is-visible": _vm.infos.showButton },
        on: {
          click: function ($event) {
            $event.preventDefault()
            return _vm.showInfos.apply(null, arguments)
          },
        },
      },
      [
        _c("i", { staticClass: "material-icons" }, [
          _vm._v("keyboard_backspace"),
        ]),
        _vm._v(" Infos\n        "),
      ]
    ),
    _vm._v(" "),
    _vm.pageName
      ? _c("div", { staticClass: "page-name" }, [_vm._v(_vm._s(_vm.pageName))])
      : _vm._e(),
    _vm._v(" "),
    _c(
      "nav",
      { staticClass: "inside-page-navigation", attrs: { role: "navigation" } },
      [
        _vm.orderID
          ? [
              _vm._l(_vm.changeOrderNavigation, function (item) {
                return [
                  _c(
                    "a",
                    _vm._b(
                      { class: { "is-hidden-on-small": !item.showOnSmall } },
                      "a",
                      _vm.orderLink(`${item.url}`),
                      false
                    ),
                    [_vm._v(_vm._s(item.label))]
                  ),
                ]
              }),
              _vm._v(" "),
              _c(
                "button",
                {
                  staticClass: "btn-menu-on-small",
                  on: {
                    click: function ($event) {
                      $event.preventDefault()
                      return _vm.toggleOrderMenu.apply(null, arguments)
                    },
                  },
                },
                [
                  _vm._v("Mehr "),
                  _c("i", { staticClass: "material-icons" }, [
                    _vm._v("keyboard_arrow_down"),
                  ]),
                ]
              ),
              _vm._v(" "),
              _c(
                "div",
                {
                  staticClass: "menu-on-small",
                  class: {
                    "is-active": _vm.orderMenu.isActive,
                    "is-visible": _vm.orderMenu.isVisible,
                  },
                },
                [
                  _vm._l(_vm.changeOrderNavigation, function (item) {
                    return [
                      _c(
                        "a",
                        _vm._b({}, "a", _vm.orderLink(`${item.url}`), false),
                        [_vm._v(_vm._s(item.label))]
                      ),
                    ]
                  }),
                ],
                2
              ),
            ]
          : _vm._e(),
      ],
      2
    ),
    _vm._v(" "),
    !_vm.isGrantedBookKeeper
      ? _c("input", {
          staticClass: "nav-search",
          attrs: {
            type: "text",
            placeholder: "Schnellsuche",
            role: "search",
            v: "",
          },
        })
      : _vm._e(),
    _vm._v(" "),
    _c(
      "button",
      {
        staticClass: "btn-show-right-side-on-small",
        class: { "is-visible": _vm.orderID },
        on: {
          click: function ($event) {
            $event.preventDefault()
            return _vm.showDashboard.apply(null, arguments)
          },
        },
      },
      [
        _c("i", { staticClass: "material-icons" }, [
          _vm._v("keyboard_backspace"),
        ]),
        _vm._v(" Infos\n        "),
      ]
    ),
    _vm._v(" "),
    !_vm.isGrantedBookKeeper
      ? _c(
          "div",
          {
            staticClass: "simple-menu btn-help is-pointer",
            on: {
              click: function ($event) {
                $event.preventDefault()
                return _vm.toggleFaq.apply(null, arguments)
              },
            },
          },
          [_c("i", { staticClass: "material-icons" }, [_vm._v("live_help")])]
        )
      : _vm._e(),
    _vm._v(" "),
    !_vm.isGrantedBookKeeper
      ? _c(
          "div",
          {
            staticClass: "simple-menu btn-help is-pointer",
            on: {
              click: function ($event) {
                $event.preventDefault()
                return _vm.toggleUserSettings.apply(null, arguments)
              },
            },
          },
          [_c("i", { staticClass: "material-icons" }, [_vm._v("settings")])]
        )
      : _vm._e(),
  ])
}
var staticRenderFns = []
render._withStripped = true

export { render, staticRenderFns }