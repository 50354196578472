<template>
   <div
       class="simple-menu"
       v-bind:class="[isVisible ? 'is-open' : '']"
       ref="wrapper"
       v-on:click.stop=""
       v-bind:title="title"
   >
        <button v-on:click.stop="open" v-if="buttonWrapper">
            <i v-if="material" class="material-icons">{{ icon }}</i>
            <i v-else aria-hidden="true" :class="icon"></i>
        </button>
        <template v-else>
            <i v-if="material" class="material-icons" v-on:click.stop="open">{{ icon }}</i>
            <i v-else aria-hidden="true" :class="icon" v-on:click.stop="open"></i>
        </template>

        <transition name="scale">
            <div
                class="simple-menu-content"
                v-if="isVisible"
                ref="dropdown"
                v-on:click="close"
            >
                <slot></slot>
            </div>
        </transition>
    </div>
</template>



<script>
export default {
    props: {
        title:    { type: String },
        material: { type: Boolean, default: true },
        icon:     { type: String,  default: 'add_circle' },
        buttonWrapper: { type: Boolean, default: true }
    },


    data: function () {
        return {
            isVisible: false
        }
    },


    methods: {
        open: function () {
            if (this.isVisible === false) {
                // Show the dropdown
                this.isVisible = true;

                // Add event listener for the blur (=> close the dropdown)
                setTimeout(() => {
                    window.document.addEventListener('click', this.close);
                }, 100);
            }
        },


        close: function () {
            // Remove event listener
            window.document.removeEventListener('click', this.close);

            // After all preparation we hide the dropdown
            this.isVisible = false;
        }
    }
}
</script>
