<template>
    <div v-bind:class="['accordion', classNames, localIsVisible ? 'is-open' : '', isCollapsible ? '' : 'is-not-collapsible']">
        <div class="accordion-header" v-on:click="toggle">
            <slot name="header"></slot>
        </div>

        <div class="accordion-content" ref="content">
            <slot name="content"></slot>
        </div>
    </div>
</template>



<script>
export default {
    props: {
        isOpen:        { type: Boolean, default: true },
        isCollapsible: { type: Boolean, default: true },
        classNames:    { default: '' }
    },


    data: function () {
        return {
            localIsVisible: true,
            inTransition:   false,
        }
    },


    methods: {
        toggle: function () {
            if (this.isCollapsible && !this.inTransition) {
                this.inTransition = true;
                if (this.localIsVisible) {
                    this.hide();

                } else {
                    this.show();
                }
            }
        },


        hide: function () {
            this.$refs.content.style.overflow = 'hidden';
            this.$refs.content.setAttribute('height', this.$refs.content.scrollHeight);
            this.$refs.content.style.height = this.$refs.content.getAttribute('height') + 'px';

            setTimeout(() => {
                this.$refs.content.style.height = 0;
            }, 10);

            setTimeout(() => {
                this.localIsVisible = false;
                this.inTransition = false;
            }, 200);

            this.$emit('isHidden');
        },


        show: function () {
            this.$refs.content.style.height = this.$refs.content.getAttribute('height') + 'px';

            setTimeout(() => {
                this.$refs.content.style.height = 'auto';
                this.$refs.content.style.overflow = 'visible';
            }, 200);

            setTimeout(() => {
                this.localIsVisible = true;
                this.inTransition = false;
                this.$emit('isOpen');
            }, 200);
        }
    },


    mounted: function () {
        this.$refs.content.setAttribute('height', this.$refs.content.scrollHeight);

        // If initial state is set to hidden
        if (!this.isOpen && this.isCollapsible) {
            this.$refs.content.style.overflow = 'hidden';
            this.$refs.content.style.height = 0;
            this.localIsVisible = false;
        }
    },


    watch: {
        isOpen: function () {
            if (this.isOpen !== this.localIsVisible) {
                this.toggle();
            }
        }
    }
}
</script>
